/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap/dist/css/bootstrap.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "./assets/common-styles/variables.scss";
.login-layout {
  display: flex;
  padding-top: 0 !important;
}

.modal { z-index: 1000000 !important;}

html,
body {
  height: 100%;
}
.theme-bg-color {
  background-color: #111111;
}

.theme-body-bg-color {
  background-color: #f4f6f9;
}

.cursor-pointer {
  cursor: pointer;
}
.h6 {
  font-size: 14px !important;
}
h4 {
  font-size: 14px !important;
}
thead tr th {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 500;
}
tbody tr td {
  font-family: Roboto, sans-serif;
  font-size: 13px;
}
.btn {
  font-size: 13px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

.btn_primary{
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 24px;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
  background: #de7141;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding-inline: 1rem;
}

.btn_secondary{
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 24px;
  border-radius: 5px;
  cursor: pointer;
  color: #de7141;
  border: 1px solid #de7141;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-inline: 1rem;
  margin-bottom: 1rem;
}

.btn_disable{
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 24px;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
  background: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding-inline: 1rem;
}


.heading h4 {
  font-weight: 600;
  padding: 10px 0;
  font-size: 18px !important;
}

.name-code {
  width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  border-radius: 99rem;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  padding-top: 5px;
  margin-right: 10px;
  text-transform: uppercase;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.attachment-badge1.badge {
  color: #fff;
  text-transform: capitalize;
  vertical-align: middle;
  font-weight: 500;
  font-size: 12px;
  background-color: #fff;
  min-width: 50px;
}

.attachment-badge1.transferofownership
{
  background-color:  #890DE9 !important;

}

.attachment-badge1.badge.authenticityrecord
{
  border: 1px solid  #CA2688 !important;
  background-color: #fff !important;
  color: #333 !important;
}

.attachment-badge1.badge.general
{
  background-color:  #3580D8 !important;
}

.attachment-badge.badge {
  color: #fff;
  text-transform: capitalize;
  vertical-align: middle;
  font-weight: 500;
  font-size: 12px;
  background-color: #fff;
  min-width: 50px;
}

.attachment-badge.transferofownership {
  background-color:  #3580D8   !important;
  padding: 9px 10px 5px 10px;
  color: #ffffff !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.07px;
  width: 69px;
  height: 33px;
  box-shadow: inset 2px -1px 6px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center
}
.attachment-color.transferofownership {
  color: #3580D8 !important;
}


.attachment-badge.badge.authenticityrecord {
  border: 1px solid #CA2688 !important;
  background-color: #CA2688!important;
  padding: 9px 10px 5px 10px;
  color: #ffffff !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.07px;
  width: 69px;
  height: 33px;
  box-shadow: inset 2px -1px 6px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
.attachment-color.badge.authenticityrecord {
  color:#CA2688!important
}


.attachment-badge.badge.general {
  background-color: #890DE9 !important;
  padding: 9px 10px 5px 10px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.07px;
    width: 69px;
    height: 33px;
    box-shadow: inset 2px -1px 6px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}
.attachment-color.badge.general {
  color: #890DE9 !important;
}

.white-text {
  color: #ffffff !important;
}

.btn-toggle-bg {
  background-color: #f8f8ff !important;
}

.side-nav-bar__content {
  // margin: 20px 0 0 4px;
  .side-nav-bar__contents {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: max-content;
  }
  .side-nav-bar__dashboard {
      display: flex;
      .side-nav-bar__dashboardText {
          margin-left: 6px;
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
          color: $orange;
          cursor: pointer;
      }
  }
  .side-nav-bar__images {
      width: 24px;
      height: 24px;
      cursor: pointer;
  }
  .side-nav-bar__network {
      display: flex;
      margin-bottom: 20px;
  }
  .side-nav-bar__contentText {
      margin-left: 6px;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      cursor: pointer;
  }
  .side-nav-bar__userText {
      margin-left: 10px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.6px;
      color: $black;
      cursor: pointer;
  }
  .side-nav-bar__settings {
      display: flex;
  }
  .side-nav-bar__options {
      margin: 20px 0 73px 0;
      display: flex;
      flex-direction: column;
      gap: 30px;
  }
  .side-nav-bar__userWrapper {
      border-top: 1px solid ;
      .side-nav-bar__user {
          display: flex;
          // margin-top: 20px;
      }
      .side-nav-bar__userImage {
          cursor: pointer;
          height: 40px;
          width: 40px;
          border-radius: 50%;
      }
  }
  .side-nav-bar__admin {
      margin-bottom: 20px;
      .side-nav-bar__userImage {
          cursor: pointer;
          height: 40px;
          width: 40px;
          border-radius: 50%;
      }
  }
}
.badge-primary {
  color: #ffffff;
  background-color: #007bff;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #ffffff;
  background-color: #0062cc;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #ffffff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #28a745;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #ffffff;
  background-color: #1e7e34;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #ffffff;
  background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #1f2d3d;
  background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #1f2d3d;
  background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #dc3545;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #ffffff;
  background-color: #bd2130;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #1f2d3d;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #1f2d3d;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.ql-editor strong {
  font-weight: bold !important;
  font-style: normal;
}
.ql-editor em {
  font-style: italic !important;
  font-weight: normal;
}


.ql-editor.ql-blank::before {
  color: rgba(0,0,0,0.6);
  content: attr(data-placeholder);
  font-style: normal !important;
  top:1px;
  left: 5px;
  pointer-events: none;
  position: absolute;
  // right: 15px;
  color: var(--text-black, #1B295C);
  /* 16 Pixels/16 Px ( Light ) */
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal !important;
  font-weight: 300;
  line-height: normal;
}

.ql-toolbar.ql-snow .ql-formats{
  margin-right: 0px !important;
}

.ql-editor {
  padding: 5px 10px !important;
}

.custom_acknowledgment_message {
  display: flex;
  align-items: center;
  justify-content: center;
  .custom_acknowledgment_content {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-left: 10px;
    color: $black;
  }
}

.dropdown{
  display: flex;
  gap: 4px;
  flex-direction: column;
  .dropdownLabel{
      font-weight: 300;
      line-height: 24px;
      font-size: 14px;
      color: $grey;
  }
}
.dropdownOptions{
  width: 400px;
  height: 40px;
  padding: 10px 0 10px 16px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-position: center right;
  background-position-x: 96%;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: $black;
}
.dropdownOptions:focus{
  outline: none;
}

/* Remove focus outline for inputs and elements with tabindex */
input:focus,
textarea:focus,
select:focus,
button:focus,
[tabindex]:focus {
    //outline: none !important;
    outline-color: #214081!important;
    box-shadow: none !important;
}

.side-nav-bar__dashboard .tooltip {
  display: none;
}
.side-nav-bar__dashboard:hover .tooltip {
  transition: opacity 0.3s ease;
  display: block;
  position: relative;
}

.panel-open {
  overflow: hidden;
}
.panel-close {
  overflow:scroll;
}
.no-white-space{
  white-space: normal!important;
text-overflow: ellipsis;
}

.ibdic-logo {
  width: 69px;
  height: 64px;
  margin-top: 14px;
}

.ibdic-name {
  width: 241px;
  height: 85px;
  margin-left: -35px;
  margin-top: 2px;
}

.ibdic-logo-sec {
  margin-top: 27px;
  margin-right: -37px;
}